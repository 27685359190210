// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.QR-Main{
    color:white;
    margin : 20px; 
}




.video-container{
    width: 400px; 
    height: 320px; 
    overflow: hidden; 
    position: absolute;
    top: 50%;
    left:50%;
    transform: translate(-50%, -50%) !important;
}


.QR-video{
    width: 100%;
    height: 100%; /* Remplissez tout l'espace */
    object-fit: cover;
    border-radius : 10px; 
}

.Message-window{
    position: fixed;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: Unbounded;
    font-size: 14px;
    color : orangered; 
    text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/vigile/QrCodeScanner.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;;;;AAKA;IACI,YAAY;IACZ,aAAa;IACb,gBAAgB;IAChB,kBAAkB;IAClB,QAAQ;IACR,QAAQ;IACR,2CAA2C;AAC/C;;;AAGA;IACI,WAAW;IACX,YAAY,EAAE,6BAA6B;IAC3C,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,QAAQ;IACR,SAAS;IACT,gCAAgC;IAChC,sBAAsB;IACtB,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".QR-Main{\n    color:white;\n    margin : 20px; \n}\n\n\n\n\n.video-container{\n    width: 400px; \n    height: 320px; \n    overflow: hidden; \n    position: absolute;\n    top: 50%;\n    left:50%;\n    transform: translate(-50%, -50%) !important;\n}\n\n\n.QR-video{\n    width: 100%;\n    height: 100%; /* Remplissez tout l'espace */\n    object-fit: cover;\n    border-radius : 10px; \n}\n\n.Message-window{\n    position: fixed;\n    top: 20%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n    font-family: Unbounded;\n    font-size: 14px;\n    color : orangered; \n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
