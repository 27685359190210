// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SumUpH1{
  color:white;
}

.p-SumUp{
  display : flex; 
  justify-content : center;
}
`, "",{"version":3,"sources":["webpack://./src/SumUpWidget.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,cAAc;EACd,wBAAwB;AAC1B","sourcesContent":[".SumUpH1{\n  color:white;\n}\n\n.p-SumUp{\n  display : flex; \n  justify-content : center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
