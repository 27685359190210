import React from 'react';
import { pages } from './resources';
import './HomePage.css'
import {api} from '../api'
import LogoBDE from '../LogoBDE.js'
import { RiFileEditLine } from "react-icons/ri";
import { FaUsers } from "react-icons/fa";
import { GrGallery } from "react-icons/gr";

function HomePage({changeCurrentPage}) {
  const handleSubmit = (event, page) => {
    event.preventDefault();
    changeCurrentPage(page);
  };

  const handleRetourClick = () => {
     window.location.href = '/';
   };

   const handleVigileClick = () => {
    window.location.href = '/vigile';
  };

  const handlePayement = async () => {
    try {
      const res = await api.post("/test");
      console.log(res.data);
    
      const id = res.data.id;

      // Redirection vers la page de paiement avec l'ID dans l'URL
      window.location.href = '/paiementPage/' + id; 
    } catch (error) {
      console.error("Erreur lors de la création du paiement:", error);
    }
  };


  return (
    <div className='HomePage-Main'>
      <header className='App-header'>
        <LogoBDE/>
        <h1 className='HomePage-Title'>Admin only</h1>
      </header>
      <div className='HomePage-MainButton-div'>
        <button onClick={(event) => handleSubmit(event, pages.EVENT)} className='HomePage-MainButton' ><RiFileEditLine alt="event edit" className='svg-qrcode'/>Gérer l'évènement</button>
        <button onClick={(event) => handleSubmit(event, pages.LISTPAGE)} className='HomePage-MainButton'><FaUsers alt="liste" className='svg-qrcode'/>Liste des inscrits</button>
        <button onClick={(event)=>handleSubmit(event, pages.NEWPOSTERPAGE)} className='HomePage-MainButton'><GrGallery alt="edit-aboutpage" className='svg-qrcode'/> Gérer la galerie</button>
      </div>
      <div className='HomePage-Button-div'>
      <button onClick={handleRetourClick} className="HomePage-Button" id="HomePage-retour">Page utilisateur</button>
      <button onClick={handleVigileClick} className="HomePage-Button" id="HomePage-retour">Page vigile</button>
      <button onClick={handlePayement} className='HomePage-Button' >Test paiement</button>
      </div>
     </div>
  );
}

export default HomePage;
