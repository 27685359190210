import React, { useEffect } from 'react';
import { api } from "./api.js";
import { useParams } from 'react-router-dom';
import './SumUpWidget.css'
const SumUpWidget = () => {

  const { id } = useParams();


  useEffect(() => {
    // Charger le script SumUp lors du montage du composant
    const script = document.createElement('script');
    script.src = 'https://gateway.sumup.com/gateway/ecom/card/v2/sdk.js';
    script.type = 'text/javascript';
    script.async = true;

    // Une fois le script chargé, monter le widget
    script.onload = () => {
      if (window.SumUpCard) {
        window.SumUpCard.mount({
          id: 'sumup-card',
          checkoutId: id,
          onResponse: (type, body) => {
            console.log('Type:', type);
            console.log('Body:', body);
	    if (type==="error" || (type==="success" && body.status==='FAILED')){
		window.location.href = '/paiement/statut/failed'
	    }
	    if (type==="success" && body.status==='PAID'){
	        window.location.href = '/paiement/statut/sucess'
	   	api.post("https://bde.ensci.com/serveur/confirmationPayement", {
		    data: body
		})
	    }
          },
        });
      }
    };

    // Ajouter le script au document
    document.body.appendChild(script);

    // Nettoyer lorsque le composant est démonté
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script);
      }
    };
  }, []); // Le tableau vide [] signifie que cet effet ne se déclenche qu'une seule fois après le premier rendu

  return (
    <div>
      <h1 className="SumUpH1">Paiement</h1>
      <div id="sumup-card"></div>
      <p className="p-SumUp">
         <a className='SignUp-text-a' href="mailto:monmail@mail.com?subject=[URGENT][BDE.ENSCI.COM]%20Je%20rencontre%20un%20problème%20à%20payer%20ma%20place%20à%20l'évènement">J'ai un problème</a>
      </p>
    </div>
  );
};

export default SumUpWidget;

