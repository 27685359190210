import { useContext, useState } from "react";
import { useDropzone } from 'react-dropzone';
import { api } from '../api';
import './AdminPage.css';
import { DataContext } from '../DataContext.js';

function StylePage({handleAlert}) {

    const { updateData } = useContext(DataContext);
    const [selectedImage, setSelectedImage] = useState(null);

    const onDrop = (acceptedFiles) => {
        setSelectedImage(acceptedFiles[0]);
    };

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    const handleSubmit = async (event) => {
        event.preventDefault();

        const color = document.getElementById('color').value;
        const credit = document.getElementById('Formulaire-Credit').value;

        const formData = {
            color,
            image: selectedImage,
            credit
        };

        try {
            const response = await api.post('/formulaire/post/style', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
            });

            if (response.status === 200) {
                console.log('Données téléchargées avec succès');
                handleAlert("Style de l'événement modifié avec succès");

                updateData({ color });
            } else {
                handleAlert("Une erreur est survenue");
                console.error(response.status);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="Formulaire-form">
            <div {...getRootProps()} className="dropzone">
                <input {...getInputProps()} />
                <p className='dropzone-p'>Sélectionnez ou glissez l'affiche</p>
            </div>
            <div className="color-div">
                <input id="color" type='color' name="background"></input>
                <label id='color-label'> Couleur du site</label>
            </div>
            {selectedImage && (
                <div>
                    <p>Image sélectionnée : {selectedImage.name}</p>
                    <img
                        src={URL.createObjectURL(selectedImage)}
                        alt="Prévisualisation"
                        style={{ maxWidth: '100%', marginTop: '10px' }}
                    />
                </div>
            )}
            <input id="Formulaire-Credit" placeholder="Crédit de l'affiche" className="Formulaire-input" />
            <div className='separateur'/>
            <button className="AdminPage-button-valid" type="submit">Valider</button>
        </form>
    );
}

export default StylePage;
