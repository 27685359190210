// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.p-annim{
    position :absolute;
    font-size: 20px;
    font-weight: bold;
}

.Price-container{
    width: calc((50vw - 105px) / 2 - 48px);
    display:flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
}


@media (max-width: 1000px) {
    .Price-container{
        width: calc(40vw - 48px);
    }
}

@media (max-width: 550px) {
    .p-annim{
        font-size: 15px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/PricesAnnim.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,sCAAsC;IACtC,YAAY;IACZ,mBAAmB;IACnB,uBAAuB;IACvB,eAAe;AACnB;;;AAGA;IACI;QACI,wBAAwB;IAC5B;AACJ;;AAEA;IACI;QACI,eAAe;IACnB;AACJ","sourcesContent":[".p-annim{\n    position :absolute;\n    font-size: 20px;\n    font-weight: bold;\n}\n\n.Price-container{\n    width: calc((50vw - 105px) / 2 - 48px);\n    display:flex;\n    align-items: center;\n    justify-content: center;\n    padding: 15px 0;\n}\n\n\n@media (max-width: 1000px) {\n    .Price-container{\n        width: calc(40vw - 48px);\n    }\n}\n\n@media (max-width: 550px) {\n    .p-annim{\n        font-size: 15px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
