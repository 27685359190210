// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-confirmation{
    transition:all 0.5s ease;
}

.smiley2{
    transition:all 0.3s ease;
    opacity: 0;
}

.active{
    opacity:1 !important;
}

.svg-confimation{
    width:70vw;
    height:70vw;
    max-width:500px;
    max-height:500px;
}`, "",{"version":3,"sources":["webpack://./src/annimConfirmation.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;IACxB,UAAU;AACd;;AAEA;IACI,oBAAoB;AACxB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".circle-confirmation{\n    transition:all 0.5s ease;\n}\n\n.smiley2{\n    transition:all 0.3s ease;\n    opacity: 0;\n}\n\n.active{\n    opacity:1 !important;\n}\n\n.svg-confimation{\n    width:70vw;\n    height:70vw;\n    max-width:500px;\n    max-height:500px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
