// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.StatusPage-Main{
    background-color:black;
    position: fixed;
    top: 0;
    padding-top: 30vh;
    display: flex;
    min-height: 100vh;
    width: 100vw;
    color: white;
    flex-direction: column;
    align-items: center;
}

.StatusPage-div-button{
    display : flex;
    grid-gap: 10px;
}

.h1-Status{
    margin-bottom:-20px;
}

.vert {
  color: lime;
}

.rouge {
  color: orangered; 
}

.StatusPage-confirm{
  color: white;
  border-radius: 7px;
  background-color: var(--color-darkgrey);
  width: 200px;
}

#status-valid-confirm{
  background-color: lime;
}


@media (max-width: 768px) {

  .StatusPage-div-button{
    flex-direction: column-reverse;
  }
}

`, "",{"version":3,"sources":["webpack://./src/vigile/ClientStatusPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,eAAe;IACf,MAAM;IACN,iBAAiB;IACjB,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,cAAc;AAClB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,uCAAuC;EACvC,YAAY;AACd;;AAEA;EACE,sBAAsB;AACxB;;;AAGA;;EAEE;IACE,8BAA8B;EAChC;AACF","sourcesContent":[".StatusPage-Main{\n    background-color:black;\n    position: fixed;\n    top: 0;\n    padding-top: 30vh;\n    display: flex;\n    min-height: 100vh;\n    width: 100vw;\n    color: white;\n    flex-direction: column;\n    align-items: center;\n}\n\n.StatusPage-div-button{\n    display : flex;\n    grid-gap: 10px;\n}\n\n.h1-Status{\n    margin-bottom:-20px;\n}\n\n.vert {\n  color: lime;\n}\n\n.rouge {\n  color: orangered; \n}\n\n.StatusPage-confirm{\n  color: white;\n  border-radius: 7px;\n  background-color: var(--color-darkgrey);\n  width: 200px;\n}\n\n#status-valid-confirm{\n  background-color: lime;\n}\n\n\n@media (max-width: 768px) {\n\n  .StatusPage-div-button{\n    flex-direction: column-reverse;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
