// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.hovered {
    animation: rotate 0.8s linear infinite;
}
.croix-cercle {
    fill: #FFFFFF;
}

.croix_svg{
    height: 8vmin;
    min-height: 40px;
    width:auto;
}

.hovered .vertical {
    transform: scaleX(0.92) scaleY(1.2);
    transform-origin: center;
    transition : All linear 0.7s;
}

.hovered .horizontal {
    transform: scaleX(1.2) scaleY(0.92);
    transform-origin: center;
    transition : All ease 0.3s;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}`, "",{"version":3,"sources":["webpack://./src/Croix.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;AAC1C;AACA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,gBAAgB;IAChB,UAAU;AACd;;AAEA;IACI,mCAAmC;IACnC,wBAAwB;IACxB,4BAA4B;AAChC;;AAEA;IACI,mCAAmC;IACnC,wBAAwB;IACxB,0BAA0B;AAC9B;;AAEA;IACI;QACI,uBAAuB;IAC3B;IACA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":[".hovered {\n    animation: rotate 0.8s linear infinite;\n}\n.croix-cercle {\n    fill: #FFFFFF;\n}\n\n.croix_svg{\n    height: 8vmin;\n    min-height: 40px;\n    width:auto;\n}\n\n.hovered .vertical {\n    transform: scaleX(0.92) scaleY(1.2);\n    transform-origin: center;\n    transition : All linear 0.7s;\n}\n\n.hovered .horizontal {\n    transform: scaleX(1.2) scaleY(0.92);\n    transform-origin: center;\n    transition : All ease 0.3s;\n}\n\n@keyframes rotate {\n    from {\n        transform: rotate(0deg);\n    }\n    to {\n        transform: rotate(360deg);\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
