import react,{ useState,useEffect,useContext } from 'react';
import './MainPage.css';
import { DataContext } from './DataContext';
import LogoBDE from './LogoBDE.js'
import Croix from './Croix.js';
import PricesAnnim from './PricesAnnim.js'

function MainPage() {


  const { data } = useContext(DataContext);
  
    const [style,setStyle]=useState({})
    const [contenuHTML, setContenuHTML]= useState({ __html: "" })
    const [textScroll, setTextScroll]= useState("")
    const [countdown, setCountdown] = useState({
        jours: 0,
        heures: 0,
        minutes: 0,
        secondes: 0
     });

    // Fonction pour mettre à jour le décompte
    const mettreAJourDecompte = () => {
        // Date actuelle
        const dateActuelle = new Date();
	    let dateFin=dateActuelle;
        if (data && data.date_fin && data.heure_fin ) {
	        dateFin = new Date(data.date_fin + "T" + data.heure_fin + ":00Z");
	        dateFin.setHours(dateFin.getHours() -2);
	    }
        
        // Calculer la différence entre la date cible et la date actuelle
        const difference = dateFin - dateActuelle;

        // Convertir la différence en jours, heures, minutes et secondes
        const jours = Math.floor(difference / (1000 * 60 * 60 * 24));
        const heures = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
        const secondes = Math.floor((difference % (1000 * 60)) / 1000);

        // Mettre à jour l'état du décompte
        setCountdown({ jours, heures, minutes, secondes });
    };

    const [domEstCharge, setDomEstCharge] = useState(false)
    const [callCount, setCallCount] = useState(0);
    const [timeRefresh, setTimeRefresh] = useState(100);
    
    useEffect(() => {
        const interval = setInterval(() => {
            mettreAJourDecompte();
            const middleColumns = document.querySelectorAll('.tracklist-point');
            if(middleColumns[0]){
                const middleColumnWidth = middleColumns[0].clientWidth;
                if(middleColumnWidth>0 && points.length === 0){
                    setDomEstCharge(true)
                }
            }
            
          if(domEstCharge && callCount < 6){
            pointTracklist();
            setCallCount(prevCount => prevCount + 1);
            if(callCount===5){
                setTimeRefresh(1000)
            }
          }
        }, timeRefresh);
      
        return () => clearInterval(interval);
      }, [mettreAJourDecompte]);

      
    useEffect(() => {
      {if (data && data.infos){
        setContenuHTML({ __html: data.infos })
      }}
    },[data])

    const formatDate = (dateString) => {
        const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
        return new Date(dateString).toLocaleDateString(undefined, options);
      };

    function formatDate2(dateString) {
        // Convertit la chaîne de caractères en objet Date
        const dateParts = dateString.split('/');
        const day = parseInt(dateParts[0], 10);
        const month = parseInt(dateParts[1], 10) - 1; // Les mois sont indexés à partir de zéro dans JavaScript
        const year = parseInt(dateParts[2], 10);
        const date = new Date(year, month, day);
      
        // Tableaux de noms de jours et de mois
        const daysOfWeek = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
        const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];
      
        // Récupère le nom du jour de la semaine, le jour, le mois et l'année
        const dayOfWeek = daysOfWeek[date.getDay()];
        const dayOfMonth = date.getDate();
        const monthOfYear = months[date.getMonth()];
        const yearValue = date.getFullYear();
      
        // Retourne la date formatée
        return `${dayOfWeek} ${dayOfMonth} ${monthOfYear} ${yearValue}`;
    }

    



    const [points, setPoints] = useState([]);
    
    // Définir la fonction pointTracklist
    function pointTracklist() {
        const middleColumns = document.querySelectorAll('.tracklist-point');
        middleColumns.forEach((middleColumn, index) => {
            const middleColumnWidth = middleColumn.clientWidth;
            const taillePoint = 16;
            let nombrePoints = Math.floor(parseFloat(middleColumnWidth) / taillePoint);
            if(nombrePoints > 60) {
                nombrePoints = 60; 
            }
            const newPoints = '.\xa0'.repeat(nombrePoints);
            setPoints(prevPoints => {
                const newPointsArray = [...prevPoints];
                newPointsArray[index] = newPoints;
                return newPointsArray;
            });
        });
    }

    useEffect(() => {
        window.addEventListener('resize', pointTracklist);
        return () => {
          window.removeEventListener('resize', pointTracklist);
        };
      }, []);
    
        
    return (
      <div className="App" style={style}>
        <header className="App-header">
                <a href="/about" className="LogoBDE">
                    <LogoBDE/>
                </a>
                <Croix/>
        </header>

        <div className="poster" > 
            <img src="https://bde.ensci.com/serveur/image" className='App-affiche' alt="affiche soiree"></img>
            {data && data.credit && <p className="credit">Affiche par {data.credit}</p>}
        </div>
        
        <main className='App-main'>
        
        {data && 
            <div className='Infos-div'>
                {data.nom && <h1 className='Main-h1 big-h1'>{data.nom}</h1>}
                {data.date && <h2 className='Main-h2' style={{ color: data.color }}>{formatDate2(formatDate(data.date))}</h2>}
                {data.lieu && <h2 className='Main-h2' > {data.lieu}</h2>}
                <hr className="Separator"></hr>

                {!data.finInscription && data.date_fin && (
                    <div className='Countdown'><p className='text-fin' >Fin des inscriptions :</p>
                        <div className='grid-countdown'>
                            <div className='Countdown-div'>
                                <div>
                                    <span className="number zero">{`${countdown.jours < 10 ? '0' : ''}`}</span>
                                    <span className="number ">{`${countdown.jours}`}</span > 
                                </div>
                                jour{countdown.jours !== 1 ? 's\xa0\xa0' : '\xa0\xa0'}
                            </div>
                            <div className='Countdown-div'>
                                <div>
                                    <span className="number zero">{`${countdown.heures < 10 ? '0' : ''}`}</span>
                                    <span className="number">{`${countdown.heures}`}</span>
                                </div>
                                heure{countdown.heures !== 1 ? 's\xa0\xa0' : '\xa0\xa0'}
                            </div>
                            <div className='Countdown-div'>
                                <div>
                                    <span className="number zero">{`${countdown.minutes < 10 ? '0' : ''}`}</span>
                                    <span className="number">{`${countdown.minutes}`}</span>
                                </div>
                                minute{countdown.minutes !== 1 ? 's\xa0\xa0' : '\xa0\xa0'}
                            </div>
                            <div className='Countdown-div'>
                                <div>
                                    <span className="number zero">{`${countdown.secondes < 10 ? '0' : ''}`}</span>
                                    <span className="number">{`${countdown.secondes}`}</span>
                                </div>
                                seconde{countdown.secondes !== 1 ? 's\xa0\xa0' : '\xa0\xa0'}
                            </div>
                        </div>
                    </div>)}
		{(!data.finInscription && data.soldOut > 0) &&
		    <div className='Prix-div'>
			<a href="/inscription" className="App-button" id="button" >
			    <b id='text-button'>Prendre ma place</b>
			</a>
			<div className='prix-display'>
                            <p className='plus'>+</p>
                            <PricesAnnim/>
                            <p className='plus'>+</p>
                   	</div>
                        {(!data.finInscription && (data.soldOut > 1 && data.soldOut< 50))  && <p className='PlaceRestante'>{data.soldOut} places restantes</p>}
                        {(!data.finInscription && (data.soldOut > 0 && data.soldOut< 2))  && <p className='PlaceRestante'>{data.soldOut} place restante</p>}
		    </div>
		}
                { data.soldOut <= 0 && <h2 className="soldOut">SOLD OUT</h2>}
                {(data.finInscription && data.soldOut>0) && <h2 className="soldOut">FIN DES INSCRIPTIONS</h2>}


                {data.infos && contenuHTML && <p className='Text-p' style={{ whiteSpace: 'pre-line' }} dangerouslySetInnerHTML={contenuHTML}></p>} 
                <hr className="Separator"></hr>
                <p className='Text-p' id='text-permanent'>
                    Réservé aux plus de 18 ans <br/> <br/>
                    STAY SAFE, TAKE CARE OF YOURSELF AND YOUR FRIENDS<br/> <br/>
                    Tout comportement malveillant, violent, agressif, sexiste, Lgbtophobe, raciste mènera à une exclusion définitive de la soirée. <br/>
                    Les membres du BDE accompagnés.ées des vigiles se réservent le droit d’exclure toute personne outrepassant les règles de bases du respect.
                </p>

                <hr className="Separator"></hr>

                <div >  
                    {data.djList && <div className='div-titre-tracklist'>
                        <h2 className='Main-h2 symbole'>⏩</h2>
                        <h2 className='Main-h2 DJset'> LINE UP</h2>
                    </div>}
                    {data.djList && data.djList.map((element, index) => (
				    (data.djList.length - index) % 2 === 0 ? (
                    <div className='Tracklist' key={element.id}>
                        <p className='Text-p tracklist-gauche'>{element.name}</p>
                        <div className='tracklist-point'> {points[index]}</div>
                        <p className='Text-p tracklist-droite'>{element.time}</p>
                    </div>
                    ) : (
                    <div className='Tracklist' key={element.id}>
                        <p className='Text-p tracklist-gauche'>{element.time}</p>
                        <div className='tracklist-point'>{points[index]} </div>
                        <p className='Text-p tracklist-droite'>{element.name}</p>
                    </div>
                    )
                    ))}
                </div>
            </div>
            }
        </main>
        
    </div>
    );
  }
  


export default MainPage;
