import axios from "axios";

const api = axios.create({
    baseURL:'https://bde.ensci.com/serveur',
    timeout:15000,
    headers: {
        "Custom-Header":"header",
        "Content-Type":"application/json",
    },
    withCredentials:true
});

export {api}
