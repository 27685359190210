import { useState} from 'react';
import './LogoBDE.css'


function LogoBDE() {

    const [circleRadius,setCircleRadius]=useState(40)

    const handleMouseOver = () => {
        setCircleRadius(45);
    };

    const handleMouseOut = () => {
        setCircleRadius(40);
    };

    const circlesData = [
        { cx: 543.67, cy: 107.51 },
        { cx: 543.67, cy: 164.21 },
        { cx: 543.67, cy: 220.9 },
        { cx: 600.36, cy: 220.9 },
        { cx: 600.36, cy: 334.29 },
        { cx: 543.67, cy: 277.59 },
        { cx: 543.67, cy: 334.29 },
        { cx: 600.36, cy: 107.51 },
        { cx: 316.91, cy: 107.51 },
        { cx: 316.91, cy: 164.21 },
        { cx: 316.91, cy: 220.9 },
        { cx: 430.3, cy: 220.9 },
        { cx: 373.61, cy: 334.29 },
        { cx: 430.3, cy: 277.59 },
        { cx: 430.3, cy: 164.21 },
        { cx: 316.91, cy: 277.59 },
        { cx: 316.91, cy: 334.27 },
        { cx: 373.61, cy: 107.51 },
        { cx: 90.14, cy: 107.51 },
        { cx: 90.14, cy: 164.21 },
        { cx: 90.14, cy: 220.9 },
        { cx: 146.83, cy: 220.9 },
        { cx: 146.83, cy: 334.29 },
        { cx: 203.53, cy: 277.59 },
        { cx: 203.53, cy: 164.21 },
        { cx: 90.14, cy: 277.59 },
        { cx: 90.14, cy: 334.27 },
        { cx: 146.83, cy: 107.51 }
      ];

    return (
        <svg
            viewBox="0 0 700 550"
            onMouseOver={() => handleMouseOver()}
            onMouseLeave={() => handleMouseOut()}
            className='App-logoBDE'
        >
            <text
                transform="translate(350 490.85)"
                style ={{
                    "fill": "white",
                    "font-size": "100px",
                    "textAnchor": "middle"
                  }}
            >
                <tspan x="0" y="0">ENSCI - Les Ateliers</tspan>
            </text>
            <g id="circles">
                {circlesData.map((circle, index) => (
                    <circle className='circles'
                        key={index}
                        cx={circle.cx}
                        cy={circle.cy}
                        r={circleRadius}
                        fill="white"
                    />
                ))}
            </g>
            

        </svg>
    )
}
export default LogoBDE
