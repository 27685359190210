import {useState} from "react"
import {api} from '../api'
import './AdminPage.css';

function PublicPage({handleAlert}){

    const [clientType, setClientType]=useState({
        enscien: false,
        invit: false,
        exte: false,
    });

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setClientType(prevState => ({
          ...prevState,
          [name]: checked,
        }));
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        const places = document.getElementById('Formulaire-Places').value;
        const nbInvit= clientType.invit ? document.getElementById('Formulaire-Invit').value :0;

        const prix = {
            enscien: clientType.enscien ? document.getElementById('Formulaire-Prix-enscien').value :false,
            invit: clientType.invit ? document.getElementById('Formulaire-Prix-invit').value :false,
            exte: clientType.exte ? document.getElementById('Formulaire-Prix-exte').value :false
        };

        const formaData= {
            places,
            nbInvit,
            prix
        }

        try {
            const response = await api.post('/formulaire/post/publics', formaData);
        
            if (response.status === 200) {
                console.log('Données téléchargées avec succès');
                handleAlert("Modifications effectuées avec succès");
            } else {
                handleAlert("Une erreur est survenue");
                console.error(response.status);
            }
        } catch (error) {
            console.error(error);
        }
        
    };



    return (
        <form onSubmit={handleSubmit} className="Formulaire-form">
            <input type='number' id="Formulaire-Places" placeholder="Nombre de places" className="Formulaire-input" />
            <br />
            <label>
              <input
                type="checkbox"
                name="enscien"
                checked={clientType.enscien}
                onChange={handleCheckboxChange}
              />
              Ensciens
            </label>
            {clientType.enscien && <input type='number' step="0.01" id="Formulaire-Prix-enscien" placeholder="Prix de la place"  className="Formulaire-input"  />}
            <br />
            <label>
              <input
                type="checkbox"
                name="invit"
                checked={clientType.invit}
                onChange={handleCheckboxChange}
              />
              Invités
            </label>
            {clientType.invit && 
                <div className="invit-div">
                    <input id="Formulaire-Invit" type='number' placeholder="Nombre par étudiant" className="Formulaire-input"/>
                    <input type='number' step="0.01" id="Formulaire-Prix-invit" placeholder="Prix de la place" className="Formulaire-input"  />
                </div>
            }
            <br />
            <label>
              <input
                type="checkbox"
                name="exte"
                checked={clientType.exte}
                onChange={handleCheckboxChange}
              />
              Extés
            </label>
            {clientType.exte && <input type='number' step="0.01" id="Formulaire-Prix-exte" placeholder="Prix de la place" className="Formulaire-input" />}
            <button className='AdminPage-button-valid' type="submit">Valider</button>
        </form>
    );
}
export default PublicPage;