// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circles{
    transition : 0.3s ease-out;
}

.logoAnnim-SVG{
    height:auto;
    width :90vmin;
    margin: -30px auto 0 auto;
}

.logoAnnim-SVG .text{
    transition : 0.3s ease-in;
    font-family: tiny, Helvetica;
    font-size: 40px;
}

@media (max-width: 1250px) {
    svg{
        width:100%
    }
}
`, "",{"version":3,"sources":["webpack://./src/LogoAnnim.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;IACzB,4BAA4B;IAC5B,eAAe;AACnB;;AAEA;IACI;QACI;IACJ;AACJ","sourcesContent":[".circles{\n    transition : 0.3s ease-out;\n}\n\n.logoAnnim-SVG{\n    height:auto;\n    width :90vmin;\n    margin: -30px auto 0 auto;\n}\n\n.logoAnnim-SVG .text{\n    transition : 0.3s ease-in;\n    font-family: tiny, Helvetica;\n    font-size: 40px;\n}\n\n@media (max-width: 1250px) {\n    svg{\n        width:100%\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
