import { useState} from 'react';
import PasswordPage from './PasswordPage';
import VigilePage from './VigilePage'
import ListPage from './ListPage'
import QrCodeScanner from './QrCodeScanner';
import {api} from '../api'
import './App.css';
import {pages} from './resources'


function App() {

  const [currentPage, setCurrentPage] = useState(pages.PASSWORDPAGE);

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const passwordValid = async (formData) => {
    try{
      const response = await api.post('/formulaire/vigile/password/', formData)
      if(response.status===200){
          setCurrentPage(pages.HOMEPAGE)
      }
      else {
        console.error(response.status)
      }
    }catch(error){ 
      console.error(error)
    }
  }



  return (
    <div className='App-Main'>
      {currentPage===pages.HOMEPAGE && <VigilePage changeCurrentPage={changeCurrentPage}/>}
      {currentPage===pages.PASSWORDPAGE && <PasswordPage passwordValid={passwordValid}/>}
      {currentPage===pages.LISTPAGE && <ListPage changeCurrentPage={changeCurrentPage}/>}
      {currentPage===pages.QRCODESCANNER && <QrCodeScanner changeCurrentPage={changeCurrentPage}/>}
     </div>
  )

}

export default App;
