import './Desinscription.css';
import {useParams} from "react-router-dom";
import CircleSVG from './annimConfirmation'
import Croix from './Croix.js';

function PaymentStatus() {

    const params = useParams();
    const status =params.status


    const handleRetourClick = () => {
        // Rediriger vers la page d'accueil lorsque le bouton Retour est cliqué
        window.location.href = '/';
      };
    
      return (
        <div className="Desinscription">
            <header className="Desinscription-header">
                <CircleSVG isValid={status==="sucess"}/>
                {status==="sucess" && <h1 className='h1-Confirmation'>Merci de vous être inscrit !</h1>}
                {status==="failed" && <h1 className='h1-Confirmation'>Le paiement a échoué !</h1>}
            </header>
            <button onClick={handleRetourClick} className="Desinscription-button" id="button">
            <b>Retour</b>
          </button>
          <Croix id="croix1"/>
	  <Croix id="croix2"/>
          <Croix id="croix3"/>
          <Croix id="croix4"/>
        </div>
      );
    }
    
export default PaymentStatus;

    
    
    
    
    
