import { useState} from 'react';
import AdminPage from './AdminPage';
import PasswordPage from './PasswordPage';
import HomePage from './HomePage'
import ListPage from './ListPage'
import NewPosterPage from './NewPosterPage';
import {api} from '../api'
import './App.css';
import {pages} from './resources'


function App() {

  const [currentPage, setCurrentPage] = useState(pages.PASSWORDPAGE);

  const changeCurrentPage = (page) => {
    setCurrentPage(page);
  };

  const passwordValid = async (formData) => {
    try{
      const response = await api.post('/formulaire/password/', formData)
      if(response.status===200){
          setCurrentPage(pages.HOMEPAGE)
          return 200
      }
      else {
        return response.status
      }
    }catch(error){ 
      console.error(error)
      return 500
    }
  }


  return (
    <div className='App-Main'>
      {currentPage===pages.EVENT && <AdminPage changeCurrentPage={changeCurrentPage}/>}
      {currentPage===pages.HOMEPAGE && <HomePage changeCurrentPage={changeCurrentPage}/>}
      {currentPage===pages.PASSWORDPAGE && <PasswordPage passwordValid={passwordValid}/>}
      {currentPage===pages.LISTPAGE && <ListPage changeCurrentPage={changeCurrentPage}/>}
      {currentPage===pages.NEWPOSTERPAGE && <NewPosterPage changeCurrentPage={changeCurrentPage}/>}
     </div>
  )

}

export default App;
