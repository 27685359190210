// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./var.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Desinscription{
    display: flex;
    flex-direction: column;
}

.Desinscription-header{
    margin : 20vh 0 2vh;
    color:white;
    text-align: center;
}

.Desinscription-header-1{
    margin : 45vh 0 2vh;
    color:white;
    text-align: center;
}

.Desinscription-button{
    margin:auto;
    text-align: center;
    height: 45px;
    line-height: 45px;
    border: none;
    border-radius: 45px;
    color: white;
    background-color: var(--color-style);
    transition: all .25s ease-out 0s;
    font-size: 17px;
    width: 200px;
}

.Desinscription-button:hover{
    background-color: var(--color-style-light);
    font-size: 18px;
}


.Desinscription-affiche{
	margin:auto;
	height:40vh;
}

.Desinscription-h3{
    color:white;
    text-align: center;
}

#croix1{
    position:fixed;
    top:30px;
    left:30px;
}

#croix2{
    position:fixed;
    top:30px;
    right:30px;
}

#croix3{
    position:fixed;
    bottom:30px;
    left:30px;
}

#croix4{
    position:fixed;
    bottom:30px;
    right:30px;
}

.h1-Confirmation{
    margin-top:-70px;
}
`, "",{"version":3,"sources":["webpack://./src/Desinscription.css"],"names":[],"mappings":"AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,mBAAmB;IACnB,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,iBAAiB;IACjB,YAAY;IACZ,mBAAmB;IACnB,YAAY;IACZ,oCAAoC;IACpC,gCAAgC;IAChC,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,0CAA0C;IAC1C,eAAe;AACnB;;;AAGA;CACC,WAAW;CACX,WAAW;AACZ;;AAEA;IACI,WAAW;IACX,kBAAkB;AACtB;;AAEA;IACI,cAAc;IACd,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,cAAc;IACd,QAAQ;IACR,UAAU;AACd;;AAEA;IACI,cAAc;IACd,WAAW;IACX,SAAS;AACb;;AAEA;IACI,cAAc;IACd,WAAW;IACX,UAAU;AACd;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":["@import './var.css';\n\n.Desinscription{\n    display: flex;\n    flex-direction: column;\n}\n\n.Desinscription-header{\n    margin : 20vh 0 2vh;\n    color:white;\n    text-align: center;\n}\n\n.Desinscription-header-1{\n    margin : 45vh 0 2vh;\n    color:white;\n    text-align: center;\n}\n\n.Desinscription-button{\n    margin:auto;\n    text-align: center;\n    height: 45px;\n    line-height: 45px;\n    border: none;\n    border-radius: 45px;\n    color: white;\n    background-color: var(--color-style);\n    transition: all .25s ease-out 0s;\n    font-size: 17px;\n    width: 200px;\n}\n\n.Desinscription-button:hover{\n    background-color: var(--color-style-light);\n    font-size: 18px;\n}\n\n\n.Desinscription-affiche{\n\tmargin:auto;\n\theight:40vh;\n}\n\n.Desinscription-h3{\n    color:white;\n    text-align: center;\n}\n\n#croix1{\n    position:fixed;\n    top:30px;\n    left:30px;\n}\n\n#croix2{\n    position:fixed;\n    top:30px;\n    right:30px;\n}\n\n#croix3{\n    position:fixed;\n    bottom:30px;\n    left:30px;\n}\n\n#croix4{\n    position:fixed;\n    bottom:30px;\n    right:30px;\n}\n\n.h1-Confirmation{\n    margin-top:-70px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
