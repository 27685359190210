// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.okPage-h1{
    margin : auto ;
    text-align: center;
    margin-top : 50vh;
    color : white;
}
`, "",{"version":3,"sources":["webpack://./src/vigile/App.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,kBAAkB;IAClB,iBAAiB;IACjB,aAAa;AACjB","sourcesContent":[".okPage-h1{\n    margin : auto ;\n    text-align: center;\n    margin-top : 50vh;\n    color : white;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
