import React, { useEffect, useState } from 'react';
import './ListPage.css';
import { api } from '../api';
import { pages } from './resources';
import * as XLSX from 'xlsx';
import ExcelJS from 'exceljs';

function ListPage({ changeCurrentPage }) {
  const [list, setList] = useState([]);
  const [deletepage, setDeletePage] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [sortBy, setSortBy] = useState('chronological'); // Default sorting by 'chronological'
  const [filteredList, setFilteredList] = useState([]); // Liste filtrée
  const [searchQuery, setSearchQuery] = useState(''); // Champ de recherche

  const getList = async () => {
    try {
      const response = await api.get('/admin/list');
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getListAsync = async () => {
    try {
      const newData = await getList();
      setList(newData);
      
      const sortedData = sortList(newData, sortBy); // Applique le tri basé sur le critère actuel
      const filtered = applySearch(sortedData, searchQuery); // Applique le filtrage actuel
      setFilteredList(filtered); // Initialise filteredList avec les données triées et filtrées
    } catch (error) {
      console.error('Erreur de récupération des données :', error);
    }
  };  

  const sortList = (data, criteria) => {
    let sortedData = [...data];
    
    if (criteria === 'nom') {
      sortedData.sort((a, b) => a.nom.localeCompare(b.nom));
    } else if (criteria === 'type') {
      sortedData.sort((a, b) => {
        const referentA = (a.nomEnsci && a.prenomEnsci) ? a.nomEnsci.toUpperCase() : 'ZZZ';
        const referentB = (b.nomEnsci && b.prenomEnsci) ? b.nomEnsci.toUpperCase() : 'ZZZ';
        return referentA.localeCompare(referentB);
      });
    }
    // Pour 'chronological', on ne trie pas les données, on garde l'ordre original
    return sortedData;
  };

  const handleSortChange = (event) => {
    const selectedCriteria = event.target.value;
    setSortBy(selectedCriteria);
  
    const sortedData = sortList([...list], selectedCriteria); // Trie la liste complète
    const filtered = applySearch(sortedData, searchQuery); // Applique le filtrage actuel
    setFilteredList(filtered); // Met à jour filteredList avec les données triées et filtrées
  };

  // Appliquer le filtrage basé sur la recherche actuelle
  const applySearch = (data, query) => {
    if (query === '') {
      return data; // Si la recherche est vide, renvoyer la liste entière triée
    }
    
    return data.filter((item) => {
      const fullName = `${item.nom} ${item.prenom}`.toLowerCase();
      const referentName = `${item.prenomEnsci || ''} ${item.nomEnsci || ''}`.toLowerCase();

      // Vérification si le nom, prénom ou élève référent correspondent à la recherche
      return (
        fullName.includes(query.toLowerCase()) ||
        referentName.includes(query.toLowerCase())
      );
    });
  };

  // Gérer la recherche
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Toujours appliquer la recherche sur la liste triée selon le critère actuel
    const sortedData = sortList([...list], sortBy); // D'abord, on trie la liste selon le critère sélectionné
    const filtered = applySearch(sortedData, query); // Ensuite, on applique le filtrage
    setFilteredList(filtered); // Mise à jour de la liste filtrée avec la recherche appliquée
  };

  useEffect(() => {
    getListAsync();
  }, []);


const handleDownload = async () => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Participants');

  // Ajout des en-têtes et mise en forme en gras
  worksheet.columns = [
    { header: '#', key: 'index', width: 5 },
    { header: 'Nom', key: 'nom', width: 20 },
    { header: 'Prénom', key: 'prenom', width: 20 },
    { header: 'Numéro', key: 'num', width: 15 },
    { header: 'Email', key: 'email', width: 30 },
    { header: 'Type', key: 'type', width: 20 },
  ];
  
  const headerRow = worksheet.getRow(1);
  headerRow.font = { bold: true }; // Texte en gras
  headerRow.eachCell((cell) => {
    // Centrer le texte dans les cellules du header
    cell.alignment = { vertical: 'middle', horizontal: 'center' };

    // Appliquer un fond gris au header
    cell.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FFD3D3D3' } // Gris clair
    };

    // Définir les bordures du header
    cell.border = {
      top: { style: 'thin' },
      left: { style: 'thin' },
      bottom: { style: 'thin' },
      right: { style: 'thin' }
    };
  });

  // Ajout des données et mise en forme des bordures et des fonds
  filteredList.forEach((item, index) => {
    const row = worksheet.addRow({
      index: index + 1,
      nom: item.nom,
      prenom: item.prenom,
      num: item.num,
      email: item.email,
      type: item.type || `${item.prenomEnsci} ${item.nomEnsci}`,
    });

    // Applique des bordures et des couleurs pour une ligne sur deux
    row.eachCell((cell) => {

      cell.alignment = { vertical: 'middle', horizontal: 'center' };

      cell.border = {
        top: { style: 'thin' },
        left: { style: 'thin' },
        bottom: { style: 'thin' },
        right: { style: 'thin' }
      };

      // Applique une couleur de fond grise pour une ligne sur deux
      if (index % 2 === 1) {
        cell.fill = {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFD3D3D3' } // Gris clair
        };
      }
    });
  });

  worksheet.eachRow((row, rowNumber) => {
     row.height = 25;  
  }); 

  // Téléchargement du fichier
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = 'participants.xlsx';
  link.click();
};



  const handleDelete = (item) => {
    setDeleteItem(item);
    setDeletePage(true);
  };

  const handleCancel = () => {
    setDeleteItem(null);
    setDeletePage(false);
  };

  const handleConfirmation = async () => {
    try {
      const res = await api.delete('/delete/' + deleteItem.id);
      if (res) {
        console.log('Inscription supprimée');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setDeleteItem(null);
      setDeletePage(false);
      getListAsync();
    }
  };

  return (
    <div className="ListPage-main">
      {deletepage && (
        <div className="DeleteConfirmationPage">
          <h3 className="ConfirmationH3">Désinscrire {deleteItem.prenom} {deleteItem.nom} ?</h3>
          <button className="ListPage-confirm-delete" onClick={handleCancel}>Annuler</button>
          <button className="ListPage-confirm-delete" id="delete-confirm" onClick={handleConfirmation}>Supprimer</button>
        </div>
      )}
      <header className="List-header">
        <button className='Bouton-Retour' onClick={() => changeCurrentPage(pages.HOMEPAGE)} alt='retour' id="homePage-return">
          {"↖"}
        </button>
        <h1 className='ListPage-h1'>Les inscrits</h1>
        <button className="DownloadButton" onClick={handleDownload}>
          Télécharger <span className='Logo-telecharge'> ⭳ </span>
        </button>
      </header>
      
      <div className="SortingDropdown">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Rechercher un participant..."
          className="SearchInput"
        />
        <h4 className="List-h4">Total : {filteredList.length}</h4>
        <select id="sort-select" value={sortBy} onChange={handleSortChange}>
          <option value="chronological">Chronologique</option>
          <option value="nom">Nom</option>
          <option value="type">Type</option>
        </select>
      </div>

      <table className="ListPage-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Numéro</th>
            <th>Email</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.map((item, index) => (
            <tr key={index}>
              <td data-label="#"> {index + 1} </td>
              <td data-label="Nom"> {item.nom} </td>
              <td data-label="Prénom"> {item.prenom} </td>
              <td data-label="Numéro"> {item.num} </td>
              <td data-label="Email"> {item.email} </td>
              <td data-label="Type"> 
                {item.prenomEnsci && item.nomEnsci ? `${item.prenomEnsci} ${item.nomEnsci}` :  `${item.type}`} 
              </td>
              <td data-label="Action">
                <button className='ListPage-delete-button' onClick={() => handleDelete(item)}>Supprimer</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ListPage;
