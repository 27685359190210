import React, { createContext, useEffect, useState } from 'react';
import {api} from './api.js'
import { lighten } from 'polished';

const DataContext = createContext();

const DataProvider = ({ children }) => {

    const [data, setData] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
        try {
            const response = await api.get('/data');
            setData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
        }
        fetchData();
    }, []);

    useEffect(() => {
       if(data){
        document.documentElement.style.setProperty('--color-style', data.color); 
        const lightenedColor = lighten(-0.1, data.color);
        document.documentElement.style.setProperty('--color-style-light', lightenedColor); 
       }
    }, [data]);

    const updateData = (newData) => {
      setData(newData); // Mise à jour des données localement
    };
  
  return (
    <DataContext.Provider value={{ data, updateData }}>
      {children}
    </DataContext.Provider>
  );
};

export { DataContext, DataProvider };
