// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App-logoBDE{
    height: 8vmin;
    min-height: 60px;
    width:auto;
    font-family: Tiny;
    font-size: 15px;
    
}
`, "",{"version":3,"sources":["webpack://./src/LogoBDE.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,gBAAgB;IAChB,UAAU;IACV,iBAAiB;IACjB,eAAe;;AAEnB","sourcesContent":[".App-logoBDE{\n    height: 8vmin;\n    min-height: 60px;\n    width:auto;\n    font-family: Tiny;\n    font-size: 15px;\n    \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
