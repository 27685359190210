import React, { useState } from 'react';
import {api} from '../api'
import './AdminPage.css';

function AddDJToList({handleAlert}) {
  const [newDJName, setNewDJName] = useState('');
  const [newDJTime, setNewDJTime] = useState('');
  const [djsList, setDJsList] = useState([]);

  const handleAddDJ = () => {
    if (newDJName && newDJTime) {
      setDJsList([...djsList, { name: newDJName, time: newDJTime }]);
      setNewDJName('');
      setNewDJTime('');
    }
  };

  const handleClearList = () => {
    setDJsList([]);
  };

  const formData={
    "djList":djsList
  }

  const handleSubmit = async()=>{
    try {
        const response = await api.post('/formulaire/post/listDJ',formData);
        if (response.status === 200) {
            handleAlert("LineUP modifié avec succès !");
        } else {
            handleAlert("Une erreur est survenue");
            console.error(response.status);
        }
    } catch (error) {
        console.error(error);
    }
  }

  return (
    <div className='Formulaire-form'>
      <label>Ajouter un nouveau dj</label>
      <input
        type="text"
        placeholder="nom du DJ"
        value={newDJName}
        className='Formulaire-input-event'
        onChange={(e) => setNewDJName(e.target.value)}
      />
      <input
        type="time"
        value={newDJTime}
        className='Formulaire-input'
        onChange={(e) => setNewDJTime(e.target.value)}
      />
      <button className="AdminPage-button-add" onClick={handleAddDJ}>Ajouter le DJ</button>

      <label>Line UP</label>
      <ul>
        {djsList.map((dj, index) => (
          <li key={index}>
            <strong>{dj.name}</strong> - {dj.time}
          </li>
        ))}
      </ul>
      <button className="AdminPage-button-empty" onClick={handleClearList}>Vider la liste</button>
      <div className='separateur'/>
      <button className='AdminPage-button-valid' onClick={handleSubmit}>Valider</button>
    </div>
  );
}

export default AddDJToList;
