import { useState, useEffect } from "react";
import {api} from '../api.js'
import { useDropzone } from 'react-dropzone';
import { pages } from './resources';
import "./NewPosterPage.css"

function NewPosterPage({changeCurrentPage}) {
    const [selectedImage, setSelectedImage] = useState(null);
    const [posters, setPosters] = useState([]);
    const [currentIndex, setCurrentIndex] = useState(-1);

    const goToPrevious = () => {
        setCurrentIndex((prevIndex) =>
          prevIndex === -1 ? posters.length-1 : prevIndex - 1
        );
      };

    const goToNext = () => {
    setCurrentIndex((prevIndex) =>
        prevIndex === posters.length-1 ? -1 : prevIndex + 1
    );
    };

    const getPostersAsync = async () => {
        try {
            const newData = await api.get('/postersList/');
            setPosters(newData.data); 
        } catch (error) {
            console.error('Erreur de récupération des données :', error);
        }
    };

    useEffect(() => {
        try {
            getPostersAsync();
        } catch (error) {
            console.error(error);
        }
    }, []);


    const onDrop = (acceptedFiles) => {
        setSelectedImage(acceptedFiles[0]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const credit = document.getElementById('PosterPage-Credit').value;
        const date = document.getElementById('PosterPage-Date').value;

        // Check if required values are present
        if (!credit || !date || !selectedImage) {
            alert('Please fill in all required fields and select an image.');
            return;
        }
        submit( { credit, date, image: selectedImage });
    };

    const submit = async (formData) => {
        try {
            const response = await api.post('/admin/newPoster/', formData, {
                headers: {
                  'Content-Type': 'multipart/form-data',
                },
            });
      
        if (response.status === 200) {
            console.log('Données téléchargées avec succès !');
            alert("Nouvel évènement créé avec succès !");
            changeCurrentPage(pages.HOMEPAGE);
        } else {
            console.error(response.status);
        }
        } catch (error) {
          console.error(error);
        } 
    };
      
      

    const { getRootProps, getInputProps } = useDropzone({ onDrop, accept: 'image/*' });

    return (
        <div className='PosterPage-main'>
            <header className="List-header">
                <button className='Bouton-Retour' onClick={() => changeCurrentPage(pages.HOMEPAGE)} alt='retour' id="homePage-return">
                {"↖"}
                </button>
                <h1 className='ListPage-h1'>La galerie</h1>
            </header>

            {posters && posters.length > 0 && <div className="carousel-container">
                {currentIndex >= 0 && <div className="carousel-content">
                    <img
                    src={`https://bde.ensci.com/serveur/posters/${posters[currentIndex].imageName}`}
                    alt={posters[currentIndex].label}
                    className="carousel-image"
                    />
                    <div className="carousel-info">
                        <p className="carousel-info-p">{posters[currentIndex].imageName}</p><br/>
                        <p className="carousel-info-p">{posters[currentIndex].date}</p>
			<p className="carousel-info-p">{posters[currentIndex].credit}</p><br/>
                        <div className="bottom-button-div">                
				 <button onClick={goToPrevious} className="carousel-button">‹</button>
				 <button className="PosterPage-button">Modifier</button>
                                 <button onClick={goToNext} className="carousel-button">›</button>
			</div>
		    </div>
                </div>}
                {currentIndex === -1 && <div className="poster-form-container">
                <form onSubmit={handleSubmit} className="poster-form">
                    <div {...getRootProps()} className="dropzone">
                        <input {...getInputProps()} />
                        <p className='dropzone-p'>Sélectionnez ou glissez l'affiche</p>
                    </div>
                    {selectedImage && (
                        <div>
                            <p>Image sélectionnée : {selectedImage.name}</p>
                            <img
                                src={URL.createObjectURL(selectedImage)}
                                alt="Prévisualisation"
                                className='PosterPage-Preview'
                            />
                        </div>
                    )}
                    <input type="text" id="PosterPage-Credit" placeholder="Nom et prénom de l'auteur"/>
                    <label className='newposter-label' htmlFor="PosterPage-Date">Date de la soirée</label>
                    <input type="date" id="PosterPage-Date"/>
		    <div className="bottom-button-div">                
			 <button onClick={goToPrevious} className="carousel-button">‹</button>
                   	 <button type="submit" className='PosterPage-button' id="PosterPage-validation"> Valider </button>
                         <button onClick={goToNext} className="carousel-button">›</button>		   
		     </div>
                </form>
            </div>}

            </div> }

        </div>
    );
}

export default NewPosterPage
