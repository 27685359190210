import { useLayoutEffect, useRef, useContext} from "react"
import gsap from "gsap"
import "./PricesAnnim.css"
import { DataContext } from './DataContext';

const PricesAnnim = () => {
  const { data } = useContext(DataContext);
  const comp = useRef(null)


  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      const spans = document.querySelectorAll(".p-annim"); // Sélectionne tous les éléments
      const tl = gsap.timeline({ repeat: -1});
      spans.forEach((span, index) => {
        tl.fromTo(span, {
          y: -30,
          opacity: 0
        },{
          y: 0,
          opacity: 1,
          ease: "back.out",
          duration: 0.5,
        })
        .to(span, {
          y: 30,
          opacity: 0,
          ease: "back.out",
          delay:2,
          duration:0.5
        });
      });
    }, comp);
  }, []);

  return (
    <div className='Price-container' ref={comp}>
      {Object.entries(data.prix)
        .filter(([key, value]) => value !== false)
        .map(([key, value], index) => (
        <span 
          key={index}
          className="p-annim">
          {key==="enscien"?"Enscien":(key=="invit"?"Invité":"Exté")} : {value !== "0" ? value +"€" : "Gratuit"}
        </span>
      ))}
    </div>
  );
};

export default PricesAnnim;


